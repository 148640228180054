import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import Utility from "../../../shared/utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
export default {
  data: () => ({
    max100Rule: [(v) => (v || "").length <= 100 || "Max Length of 100 character", (v) => !!v || "Field is required"],
    max80Rule: [(v) => (v || "").length <= 80 || "Max Length of 80 character", (v) => !!v || "Field is required"],
    max500Rule: [(v) => (v || "").length <= 500 || "Max Length of 500 character", (v) => !!v || "Field is required"],
    max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
    page: "",
    encryptedUserId: "",
    userId: EncryptUtility.localStorageDecrypt("userID"),
    parentId: null,
    index: 0,
    dialog: false,
    disableSubGroup: true,
    disablePage: true,
    disableAction: true,
    mainPage: "",
    subPage: "",
    subAction: "",
    pageUrl: "",
    showAdd: false,
    favouriteList: [],
    friendlyName: "",
    sysName: "",
    darkMode: JSON.parse(EncryptUtility.localStorageDecrypt("theme")),
    group: [],
    subGroup: [],
    actionLevelList: [],
    tempActionLevelList: [],
    pageUrlSelected: false,
    valid: true,
    subGroupPartentId: null,
    menuId: 0,
    menuListData: [],
    temmenuListData: [],
    deleteMenuDialog: false,
    dialogDelete: false,
    addMenuDialog: false,
    icon: "",
    url: "",
    deleteLevel: 0,
    deleteMenuId: 0,
    tempsubGroupList: [],
    groupParentId: null,
    deleteShow: false,
    headers: [
      {
        text: "Group",
        value: "group",
        align: "start",
        class: "primary customwhite--text",
        width: "17%",
      },
      {
        text: "Subgroup",
        value: "subgroup",
        class: "primary customwhite--text",
        width: "17%",
      },
      {
        text: "Pages",
        value: "pages",
        class: "primary customwhite--text",
        width: "17%",
      },
      {
        text: " Page Actions",
        value: "allowedactions",
        class: "primary customwhite--text",
        width: "17%",
      },
      {
        text: " Page Sub-Action",
        value: "allowedSubActions",
        class: "primary customwhite--text",
        width: "17%",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "primary customwhite--text",
        width: "15%",
      },
    ],
    menuList: [
      { menu: "Group", value: 1 },
      { menu: "Subgroup", value: 2 },
      { menu: "Page", value: 3 },
      { menu: "Page Action", value: 4 },
      { menu: "Page Sub-Action", value: 5 },
    ],
    editSubGroupName: "",
    editGroupName: "",
    editPage: "",
    editGroup: "",
    editUrl: "",
    allowedEdit: false,
    editSubAction: "",
    editIcon: "",
    editShowSubActionTextBox: false,
    editShowActionDropDown: false,
    editSubGroupValue: "",
    editSubActionText: "",
    groupList: [],
    subGroupList: [],
    tempGroupList: [],
    isAction: false,
    pageList: [],
    tempPageList: [],
    editPageUrlSelected: false,
    showGroupDropDown: false,
    showGroupTextBox: false,
    showSubGroupTextBox: false,
    showSubGroupDropDown: false,
    showPageDropDown: false,
    showPageTextBox: false,
    editMenuDialog: false,
    showActionTextBox: false,
    showActionDropDown: false,
    showSubActionTextBox: false,
    pageParentId: null,
    editShowGroupTextBox: false,
    editShowGroupDropDown: false,
    editShowSubGroupTextBox: false,
    editShowSubGroupDropDown: false,
    editShowPageDropDown: false,
    editShowPageTextBox: false,
    editShowActionTextBox: false,
    editPageUrl: "",
    actionList: [],
    pageId: 0,
    route: "",
    backSlash: true,
    isFormValid: false,
    groupName: 0,
    listGroup: [],
    subGroupName: 0,
    listSubGroup: [],
    refresh: true,
    searchText: "",
    iconList: [
      "mdi-phone",
      "mdi-ab-testing",
      "mdi-access-point",
      "mdi-format-clear",
      "mdi-account",
      "mdi-account-box",
      "mdi-account-key",
      "mdi-checkbox-blank",
      "mdi-checkbox-blank-circle",
      "mdi-currency-btc",
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getMenuList();
    this.getGroupList();
  },
  methods: {
    // TO load the menu
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    row_classes(item) {
      if (item.subgroup == null || item.subgroup == undefined) {
        return this.darkMode ? "grey darken-1" : "grey lighten-1";
      } else if (item.pages == null || item.pages == undefined) {
        return this.darkMode ? "grey darken-2" : "grey lighten-2";
      } else {
        return "";
      }
    },
    resetFunction() {
      this.searchText = "";
      this.groupName = 0;
      this.subGroupName = 0;
      this.listGroup = [];
      this.listSubGroup = [];
      this.getMenuList();
      this.getGroupList();
    },
    getMenuList() {
      this.parentId = 0;
      this.menuListData = [];
      this.temmenuListData = [];
      this.subGroupList = [];
      this.tempPageList = [];
      this.pageList = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let menuObj = {
        search_str: "",
        menu_id: 0,
        install_id: parseInt(EncryptUtility.localStorageDecrypt("installId")),
      };
      this.axios
        .post("ap/menu_list", menuObj)
        .then((response) => {
          if (response.status == 200) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            responsedata.forEach((element) => {
              // Check if its level is 0//Group
              if (element.Level == 0) {
                this.menuListData.push({
                  group: element.FriendlyName,
                  menuid: element.MenuId,
                  level: element.Level,
                });
                this.groupList.push({
                  group: element.FriendlyName,
                  menuid: element.MenuId,
                  level: element.Level,
                });
              }
              // Check if its level is 1
              // To get the sub-group Name
              else if (element.Level == 1) {
                let menudetails = responsedata.filter((menu) => menu.MenuId == element.ParentId);
                this.menuListData.push({
                  subgroup: element.FriendlyName,
                  menuid: element.MenuId,
                  group: menudetails[this.index].FriendlyName,
                  icon: element.Icon,
                  level: element.Level,
                });
                this.subGroupList.push({
                  level: element.Level,
                  subgroup: element.FriendlyName,
                  menuid: element.MenuId,
                  parentid: element.ParentId,
                  icon: element.Icon,
                });
                this.tempsubGroupList.push({
                  level: element.Level,
                  subgroup: element.FriendlyName,
                  menuid: element.MenuId,
                  parentid: element.ParentId,
                  icon: element.Icon,
                });
              }
              // Check if its level is 2// page
              else if (element.Level == 2) {
                // To get the group Name
                let menudetails = responsedata.filter((menu) => menu.MenuId == element.ParentId);
                // To get the subgroup Name
                let parentdetails = responsedata.filter((menu) => menu.MenuId == menudetails[this.index].ParentId);
                this.menuListData.push({
                  pages: element.FriendlyName,
                  menuid: element.MenuId,
                  group: parentdetails[this.index].FriendlyName,
                  subgroup: menudetails[this.index].FriendlyName,
                  url: element.Url,
                  level: element.Level,
                });
                this.tempPageList.push({
                  pages: element.FriendlyName,
                  menuid: element.MenuId,
                  parentid: element.ParentId,
                  level: element.Level,
                });
              }
              // Check if its level is 3// page action
              else if (element.Level == 3) {
                // To get the Pages Name
                let menudetails = responsedata.filter((menu) => menu.MenuId == element.ParentId);
                // To get the subgroup Name
                let parentdetails = responsedata.filter((menu) => menu.MenuId == menudetails[this.index].ParentId);
                // To get the group Name
                let groupdetails = responsedata.filter((menu) => menu.MenuId == parentdetails[this.index].ParentId);
                this.subGroupList.push({
                  level: element.Level,
                  subgroup: element.FriendlyName,
                  menuid: element.MenuId,
                  parentid: element.ParentId,
                  icon: element.Icon,
                });
                this.menuListData.push({
                  allowedactions: element.FriendlyName,
                  group: groupdetails[this.index].FriendlyName,
                  menuid: element.MenuId,
                  subgroup: parentdetails[this.index].FriendlyName,
                  pages: menudetails[this.index].FriendlyName,
                  level: element.Level,
                  url: element.Url,
                });
                this.tempActionLevelList.push({
                  level: element.Level,
                  action: element.FriendlyName,
                  menuid: element.MenuId,
                  parentid: element.ParentId,
                });
                this.actionLevelList.push({
                  level: element.Level,
                  action: element.FriendlyName,
                  menuid: element.MenuId,
                  parentid: element.ParentId,
                });
              } // Check if its level is 4// sub-page action
              else if (element.Level == 4) {
                // To get the Pages Name
                let menudetails = responsedata.filter((menu) => menu.MenuId == element.ParentId);
                // To get the subgroup Name
                let parentdetails = responsedata.filter((menu) => menu.MenuId == menudetails[this.index].ParentId);
                // To get the group Name
                let subGroupdetails = responsedata.filter((menu) => menu.MenuId == parentdetails[this.index].ParentId);
                let groupdetails = responsedata.filter((menu) => menu.MenuId == subGroupdetails[this.index].ParentId);
                this.menuListData.push({
                  allowedSubActions: element.FriendlyName,
                  allowedactions: menudetails[this.index].FriendlyName,
                  group: groupdetails[this.index].FriendlyName,
                  menuid: element.MenuId,
                  subgroup: subGroupdetails[this.index].FriendlyName,
                  pages: parentdetails[this.index].FriendlyName,
                  level: element.Level,
                  url: element.Url,
                });
              }

              this.temmenuListData = this.menuListData;
            });
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //to edit  the menu
    editItem(item) {
      this.editMenuDialog = true;
      // To edit the group
      switch (item.level) {
        //if group is selected
        case 0:
          this.menuId = item.menuid;
          this.editGroup = item.group;
          this.editShowGroupTextBox = true;
          this.editShowGroupDropDown = false;
          this.editShowSubGroupTextBox = false;
          this.editShowSubGroupDropDown = false;
          this.editShowPageDropDown = false;
          this.editShowPageTextBox = false;
          this.editShowActionTextBox = false;
          break;
        //if  sub group isselected
        case 1:
          this.menuId = item.menuid;
          this.editSubGroup = item.group;
          this.editSubGroupName = item.subgroup;
          this.editIcon = item.icon;
          this.editShowGroupTextBox = false;
          this.editShowGroupDropDown = true;
          this.editShowSubGroupTextBox = true;
          this.editShowSubGroupDropDown = false;
          this.editShowPageDropDown = false;
          this.editShowPageTextBox = false;
          this.editShowActionTextBox = false;
          break;
        //if page is selected
        case 2:
          this.menuId = item.menuid;
          this.editSubGroup = item.group;
          this.editSubGroupValue = item.subgroup;
          this.editPage = item.pages;
          this.editUrl = item.url;
          this.editShowGroupTextBox = false;
          this.editShowGroupDropDown = true;
          this.editShowSubGroupTextBox = false;
          this.editShowSubGroupDropDown = true;
          this.editShowPageDropDown = false;
          this.editShowPageTextBox = true;
          this.editShowActionTextBox = false;
          break;
        //if pageaction is selected
        case 3:
          this.menuId = item.menuid;
          this.editSubGroup = item.group;
          this.editSubGroupValue = item.subgroup;
          this.showEditPageDropdown = item.pages;
          this.editAction = item.allowedactions;
          this.editShowGroupTextBox = false;
          this.editShowGroupDropDown = true;
          this.editShowSubGroupTextBox = false;
          this.editShowSubGroupDropDown = true;
          this.editShowPageDropDown = true;
          this.editShowPageTextBox = false;
          this.editShowActionTextBox = true;
          if (item.url != "" || item.url.length > 0) {
            this.editPageUrlSelected = true;
            this.editUrl = item.url;
          }
          break;
        case 4:
          this.menuId = item.menuid;
          this.editSubGroup = item.group;
          this.editSubGroupValue = item.subgroup;
          this.editAction = item.allowedactions;
          this.showEditPageDropdown = item.pages;
          this.editSubAction = item.allowedSubActions;
          this.editShowGroupTextBox = false;
          this.editShowGroupDropDown = true;
          this.editShowSubGroupTextBox = false;
          this.editShowSubGroupDropDown = true;
          this.editShowPageDropDown = true;
          this.editShowPageTextBox = false;
          this.editShowActionTextBox = false;
          this.editShowSubActionTextBox = true;
          this.editShowActionDropDown = true;
          break;
        default:
          break;
      }
    },
    //To delete Item
    deleteItem(item) {
      if (item.level > 2) this.deleteShow = true;
      this.deleteLevel = item.level;
      this.deleteMenuId = item.menuid;
    },
    //To add menu
    addMenu() {
      //to check form
      if (this.$refs.formAddMenu.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        //Data for post
        switch (this.menu.value) {
          case 1:
            this.parentId = null;
            break;
          case 2:
            this.parentId = this.groupParentId;
            break;
          case 3:
            this.parentId = this.subGroupPartentId;
            break;
          case 4:
            this.parentId = this.pageParentId;
            break;
          case 5:
            this.parentId = this.actionParentId;
            break;
          default:
            break;
        }
        let groupdata = {
          Level: this.menu.value - 1,
          ParentId: this.parentId,
          SystemName: this.sysName,
          FriendlyName: this.friendlyName,
          Url: this.url,
          IsAction: this.isAction,
          Createdby: this.userId,
          Icon: this.icon,
          InstallId: parseInt(EncryptUtility.localStorageDecrypt("installId")),
        };
        if (this.sysName !== null && this.sysName !== undefined) {
          if (this.friendlyName !== null && this.friendlyName !== undefined) {
            //API call
            this.axios

              .post("/ap/menu_create", groupdata)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.addMenuDialog = false;
                  this.$refs.formAddMenu.reset();
                  this.groupParentId = null;
                  this.subGroupPartentId = null;
                  this.pageParentId = null;
                  this.getMenuList();
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.validationRemoval();
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.addMenuDialog = false;
                  this.$refs.formAddMenu.reset();
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.validationRemoval();
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
                this.$refs.formAddMenu.reset();
                this.validationRemoval();
              });
          }
        }
      } else {
        this.addMenuDialog = true;
      }
    },
    //To change the group in dropdowm
    onGroupChange() {
      if (this.group !== null && this.group !== undefined) {
        this.disableSubGroup = false;
        this.subGroupList = this.tempsubGroupList;
        this.groupParentId = this.group.menuid;
        //To get the cascading list of submenu
        this.subGroupList = this.tempsubGroupList.filter((subgroup) => subgroup.parentid == this.groupParentId);
      }
    },
    //To change the Subgroup in dropdowm
    onSubGroupChange() {
      if (this.subGroup !== null && this.subGroup !== undefined) {
        this.disablePage = false;
        this.pageList = this.tempPageList;
        this.subGroupPartentId = this.subGroup.menuid;
        //To get the cascading list of Page
        this.pageList = this.tempPageList.filter((page) => page.parentid == this.subGroupPartentId);
      }
    },
    //To change the page in dropdowm
    onPageChange() {
      if (this.page !== null && this.page !== undefined) {
        this.disableAction = false;
        this.isAction = true;
        this.pageParentId = this.page.menuid;
        this.actionLevelList = this.tempActionLevelList.filter((action) => action.parentid == this.pageParentId);
      }
    },
    onActionChange() {
      if (this.action !== null && this.action !== undefined) {
        this.isAction = true;
        this.actionParentId = this.action.menuid;
      }
    },
    //To Add new menu
    onNewMenuClick() {
      this.addMenuDialog = true;
      this.groupParentId = "";
      this.sysName = "";
      this.friendlyName = "";
      this.url = "";
      this.isAction = false;
      this.icon = "";
    },
    //To cancel in dialog box
    onClickCancel() {
      if (this.editMenuDialog) this.$refs.formEditMenu.resetValidation();
      if (this.addMenuDialog) {
        this.$refs.formAddMenu.resetValidation();
        this.group = [];
        this.subGroup = [];
        this.disableSubGroup = true;
      }
      this.addMenuDialog = false;
      this.deleteMenuDialog = false;
      this.editMenuDialog = false;
      this.allowedEdit = false;
      this.menu = "";
      this.editUrl = "";
      this.editPageUrlSelected = false;
      this.validationRemoval();
    },
    //Chane in Menu Such as groups,subgroups
    onMenuChange() {
      if (this.menu !== null && this.menu !== undefined) {
        switch (this.menu.value) {
          //if group is selected
          case 1:
            this.showGroupTextBox = true;
            this.showGroupDropDown = false;
            this.showSubGroupTextBox = false;
            this.showSubGroupDropDown = false;
            this.showPageDropDown = false;
            this.showPageTextBox = false;
            this.showActionTextBox = false;
            this.showActionDropDown = false;
            this.showSubActionTextBox = false;
            break;
          //if  sub group isselected
          case 2:
            this.showGroupTextBox = false;
            this.showGroupDropDown = true;
            this.showSubGroupTextBox = true;
            this.showSubGroupDropDown = false;
            this.showPageDropDown = false;
            this.showPageTextBox = false;
            this.showActionTextBox = false;
            this.showActionDropDown = false;
            this.showSubActionTextBox = false;
            break;
          //if page is selected
          case 3:
            this.showGroupTextBox = false;
            this.showGroupDropDown = true;
            this.showSubGroupTextBox = false;
            this.showSubGroupDropDown = true;
            this.showPageDropDown = false;
            this.showPageTextBox = true;
            this.showActionTextBox = false;
            this.showActionDropDown = false;
            this.showSubActionTextBox = false;
            break;
          //if pageaction is selected
          case 4:
            this.showGroupTextBox = false;
            this.showGroupDropDown = true;
            this.showSubGroupTextBox = false;
            this.showSubGroupDropDown = true;
            this.showPageDropDown = true;
            this.showPageTextBox = false;
            this.showActionTextBox = true;
            this.showActionDropDown = false;
            this.showSubActionTextBox = false;
            break;
          // if subaction is selected
          case 5:
            this.showGroupTextBox = false;
            this.showGroupDropDown = true;
            this.showSubGroupTextBox = false;
            this.showSubGroupDropDown = true;
            this.showPageDropDown = true;
            this.showPageTextBox = false;
            this.showActionTextBox = false;
            this.showActionDropDown = true;
            this.showSubActionTextBox = true;
            break;
          default:
            break;
        }
      }
    },
    //Validation removal after submitting form or group
    validationRemoval() {
      this.showGroupTextBox = false;
      this.showGroupDropDown = false;
      this.showSubGroupTextBox = false;
      this.showSubGroupDropDown = false;
      this.showPageDropDown = false;
      this.showPageTextBox = false;
      this.showActionTextBox = false;
      this.pageUrlSelected = false;
      this.editShowGroupTextBox = false;
      this.editShowGroupDropDown = false;
      this.editShowSubGroupTextBox = false;
      this.editShowSubGroupDropDown = false;
      this.editShowPageDropDown = false;
      this.editShowPageTextBox = false;
      this.editShowActionTextBox = false;
      this.editPageUrlSelected = false;
    },
    //To allow the view details to edit
    allowEdit() {
      this.allowedEdit = true;
    },
    allowDelete() {
      this.deleteMenuDialog = true;
    },
    //To update menu
    updateMenu() {
      if (this.$refs.formEditMenu.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        if (this.editPageUrlSelected == false && this.editShowActionTextBox == true) {
          this.editUrl = "";
        }
        let groupdata = {
          MenuID: this.menuId,
          FriendlyName: this.editShowGroupTextBox
            ? this.editGroup
            : this.editShowSubGroupTextBox
              ? this.editSubGroupName
              : this.editShowPageTextBox
                ? this.editPage
                : this.editSubAction,
          Url: this.editUrl,
          Icon: this.editIcon,
          UserId: this.userId,
        };
        if (groupdata.FriendlyName !== null && groupdata.FriendlyName !== undefined) {
          if (groupdata.MenuID !== null && groupdata.MenuID !== undefined) {
            this.axios
              .post("/ap/menu_update", groupdata)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.getMenuList();
                  this.$refs.formEditMenu.reset();
                  this.editPageUrlSelected = false;
                  (this.editUrl = ""), (this.allowedEdit = false);
                  this.editMenuDialog = false;
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.editMenuDialog = false;
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          }
        }
      } else {
        this.editMenuDialog = true;
      }
    },
    //Delete Menu
    deleteMenu() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let groupdata = {
        Level: this.deleteLevel,
        MenuId: this.deleteMenuId,
        UserId: this.userId,
      };
      if (groupdata.MenuId !== null && groupdata.MenuId !== undefined) {
        if (groupdata.Level !== null && groupdata.Level !== undefined) {
          this.axios
            .post("/ap/menu_delete", groupdata)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.getMenuList();
                this.deleteMenuDialog = false;
                this.editMenuDialog = false;
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.deleteMenuDialog = false;
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },
    //to auto-fill friendlyname from system name
    setFriendlyName(sysName) {
      this.friendlyName = sysName;
    },
    //Add or remove Favourtite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Close the dialog
    closeDialog() {
      if (this.editMenuDialog) this.$refs.formEditMenu.resetValidation();
      if (this.addMenuDialog) this.$refs.formAddMenu.resetValidation();
      this.validationRemoval();
      this.addMenuDialog = false;
      this.deleteMenuDialog = false;
      this.editMenuDialog = false;
      this.allowedEdit = false;
      this.menu = "";
      this.editUrl = "";
      this.editPageUrlSelected = false;
    },
    //Group and Subgroup Details
    getGroupList() {
      this.groupName = 0;
      this.listGroup = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`ap/menu_group_subgroup?pass=1&grp_menu_id=1&user_id=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.listGroup = responsedata;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Once Group is selected
    groupSelected() {
      if (this.groupName !== null && this.groupName !== undefined) {
        this.subGroupName = 0;
        this.listSubGroup = [];
        if (this.temmenuListData.length !== 0) {
          let selGroup = this.temmenuListData.filter((obj) => obj.menuid === this.groupName);
          this.menuListData = this.temmenuListData.filter((obj) => obj.group === selGroup[0].group);
        }

        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .get(`ap/menu_group_subgroup?pass=2&grp_menu_id=${this.groupName}&user_id=${this.userId}`)
          .then((response) => {
            if (response.status == 200) {
              let responsedata = [];
              responsedata = JSON.parse(response.data.body.message);
              this.listSubGroup = responsedata;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Sub Group Selected
    subGroupSelected() {
      if (this.subGroupName !== null && this.subGroupName !== undefined) {
        if (this.temmenuListData.length !== 0) {
          let selSubGroup = this.temmenuListData.filter((obj) => obj.menuid === this.subGroupName);
          this.menuListData = this.temmenuListData.filter((obj) => obj.subgroup === selSubGroup[0].subgroup);
        }
      }
    },
    //Search cancel button click event
    onSearchCancel() {
      this.searchText = "";
      this.groupName = 0;
      this.subGroupName = 0;
      this.getMenuList();
      this.getGroupList();
    },
  },
  components: {
    breadcrumbComp,
  },
};
